import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { DashboardSearchCard } from './DashboardSearchCard';
import { notificationController } from '@app/controllers/notificationController';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { fetchSearchFormData } from '@app/store/slices/searchSlice';
import { Search } from '@app/domain/UserModel';
import { searchesProperties } from '@app/api/properties/properties.api';
import { BaseRow } from '../common/BaseRow/BaseRow';
import * as S from '../../pages/DashboardPages/DashboardPage.styles';
import ActivityView from './ActivityView';
import { BaseCard } from '../common/BaseCard/BaseCard';

const RenterDashboard: React.FC = () => {
  const { isDesktop } = useResponsive();
  const searchRequestData = useAppSelector((state) => state.searches.searchData);
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const searchFormValues = useMemo(() => searchRequestData[0] ?? undefined, [searchRequestData]);
  const [form] = BaseButtonsForm.useForm();

  useEffect(() => {
    loadSearchFormData();
  }, []);

  const loadSearchFormData = () => {
    dispatch(fetchSearchFormData());
  };

  const onFinish = useCallback(
    async (values: any) => {
      const updatedSearch = { ...searchRequestData[0], ...values, move_date: values.moving_date?.format('YYYY-MM-DD') };
      setLoading(true);
      await searchesProperties(updatedSearch);
      setLoading(false);
      notificationController.success({ message: 'Search saved!', placement: 'bottomRight' });
    },
    [searchRequestData],
  );

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={8} xxl={4}>
        <S.ScrollWrapper>
          <DashboardSearchCard
            form={form}
            searchFormValues={searchFormValues}
            isLoading={isLoading}
            onFinish={onFinish}
          />
        </S.ScrollWrapper>
      </S.LeftSideCol>

      <S.RightSideCol xl={16} xxl={20}>
        <ActivityView />
      </S.RightSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      {searchFormValues && (
        <DashboardSearchCard
          form={form}
          searchFormValues={searchFormValues}
          isLoading={isLoading}
          onFinish={onFinish}
        />
      )}
      <BaseCard>
        <ActivityView />
      </BaseCard>
    </BaseRow>
  );

  return <>{isDesktop ? desktopLayout : mobileAndTabletLayout}</>;
};

export default RenterDashboard;

export const ResultWrapper = styled.div`
  max-width: 55rem;

  @media only screen and ${media.xs} {
    width: 100%;
  }

  @media only screen and ${media.md} {
    width: 100%;
  }
`;
