import React, { useRef, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseBadge } from '@app/components/common/BaseBadge/BaseBadge';
import { NotificationsOverlay } from '@app/components/header/notificationsDropdown/NotificationsOverlay/NotificationsOverlay';
import { notifications as fetchedNotifications, Notification } from '@app/api/notifications.api';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';

export const NotificationsDropdown: React.FC = () => {
  const [isOpened, setOpened] = useState(false);
  const popOverRef = useRef<HTMLDivElement>(null);

  const handlePopOverOpen = (value: boolean) => {
    setOpened(value);
    console.log(value);
  };

  return (
    <BasePopover
      ref={popOverRef}
      trigger="click"
      content={<NotificationsOverlay hidePopOver={(val) => setOpened(!val)} />}
      onOpenChange={handlePopOverOpen}
    >
      <HeaderActionWrapper>
        <BaseButton
          type={isOpened ? 'ghost' : 'text'}
          icon={
            <BaseBadge dot>
              <BellOutlined />
            </BaseBadge>
          }
        />
      </HeaderActionWrapper>
    </BasePopover>
  );
};
