import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BaseNotification } from '@app/components/common/BaseNotification/BaseNotification';
import * as S from './NotificationsOverlay.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { formatDistanceToNow } from 'date-fns';
import { UserRole } from '@app/constants/enums/userRoles';
import { markAsReadActivity } from '@app/api/activity.api';
import { getActivities } from '@app/store/slices/activitySlice';
import { BaseDivider } from '@app/components/common/BaseDivider/BaseDivider';
import styled from 'styled-components';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { Property } from '@app/domain/Property';

interface NotificationsOverlayProps {
  hidePopOver: (value: boolean) => void;
}

export const NotificationsOverlay: React.FC<NotificationsOverlayProps> = ({ hidePopOver, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const activities = useAppSelector((state) => state.activities.activities);
  const user = useAppSelector((state) => state.user.user);

  const getPropertyTitle = (property?: Property | null) => {
    let title = '';
    let subTitle = '';
    if (property?.address_line_1) {
      title = property.address_line_1;
      subTitle = `${property.area}, ${property.address_line_4}`;
    } else if (property?.address_line_2) {
      title = `${property.address_line_2}`;
      subTitle = `${property.area}, ${property.address_line_4}`;
    } else if (property?.address_line_3) {
      title = `${property.address_line_3}`;
      subTitle = `${property.area}, ${property.address_line_4}`;
    } else if (property?.address_line_4) {
      title = `${property.address_line_4}`;
      subTitle = `${property.area}`;
    }
    return { title: title, subTitle: subTitle };
  };

  const handleMarkAllAsRead = () => {
    setLoading(true);
    activities.forEach((item) => {
      handleMarkAsRead(item.id, { refresh: false });
    });
    dispatch(getActivities()).then(() => {
      setLoading(false);
    });
  };

  const handleUpgradePlan = () => {
    hidePopOver(true);
  };

  const handleMarkAsRead = async (activityId: number, { refresh = false }) => {
    await markAsReadActivity(activityId);
    if (refresh) {
      dispatch(getActivities());
    }
  };

  const renderPricingPlanInfo = () => {
    return (
      <BaseNotification
        activityId={0}
        key={'pricingPlan'}
        type={'info'}
        {...{
          title: 'Subscriptions & Billing',
          description: (
            <Trans>
              {`You are in the Start up plan, upgrade it to get more benefits.`}
              <S.LinkBtn type="link">
                <Link onClick={handleUpgradePlan} to={'/pricing-plans'}>
                  Upgrade
                </Link>
              </S.LinkBtn>
            </Trans>
          ),
        }}
      />
    );
  };

  const noticesList = useMemo(
    () =>
      activities
        .filter((item) => item.data.type !== 'userSearchMatches')
        .map((notification, index) => {
          const type = notification.data?.type === 'matches' ? 'success' : 'share';
          const title =
            notification.data?.type === 'matches' && notification.data?.property
              ? 'Property Matches'
              : !notification.data?.type && notification.data?.agency
              ? 'Profile request'
              : 'Shared Profile';

          const timeAgoString = formatDistanceToNow(new Date(notification?.created_at), { addSuffix: true });
          const descriptions =
            notification.data?.type === 'matches' ? (
              <BaseRow style={{ alignContent: 'center', alignItems: 'center' }}>
                <S.LinkBtn type="link">{getPropertyTitle(notification?.data?.property).title}</S.LinkBtn>
                {`  has matches  `}
                {notification.data.amount} searches
              </BaseRow>
            ) : !notification.data?.type && notification.data?.agency ? (
              <BaseRow style={{ alignContent: 'center', alignItems: 'center' }}>
                {`${notification.data.agency?.name} has requested you to share your profile with them.`}
              </BaseRow>
            ) : (
              <BaseRow style={{ alignContent: 'center', alignItems: 'center' }}>
                <S.LinkBtn type="link">
                  {`${notification.data.profile?.first_name} ${notification.data.profile?.last_name}`}
                </S.LinkBtn>
                {` profile is being shared with your agency`}
              </BaseRow>
            );

          return (
            <BaseNotification
              activityId={notification.id}
              key={index}
              type={type}
              {...{
                title: title,
                subTitle: timeAgoString,
                description: descriptions,
              }}
            />
          );
        }),
    [activities],
  );

  return (
    <S.NoticesOverlayMenu {...props}>
      <BaseRow gutter={[20, 20]}>
        <BaseCol style={{ paddingBottom: 50 }} span={24}>
          {activities.length > 0 ? (
            <BaseSpace direction="vertical" size={10} split={<S.SplitDivider />}>
              {/* {user?.role === UserRole.AGENCY_ADMIN && renderPricingPlanInfo()} */}
              {noticesList}
            </BaseSpace>
          ) : (
            <S.Text>{t('header.notifications.noNotifications')}</S.Text>
          )}
        </BaseCol>
        <BaseCol
          style={{
            position: 'absolute',
            bottom: 10,
            left: 10,
            right: 10,
          }}
          span={24}
        >
          {activities.length > 0 && (
            <BaseCol span={24}>
              <S.Btn type="ghost" loading={loading} onClick={handleMarkAllAsRead}>
                {t('header.notifications.readAll')}
              </S.Btn>
            </BaseCol>
          )}
        </BaseCol>
      </BaseRow>
    </S.NoticesOverlayMenu>
  );
};

export const SplitDivider = styled(BaseDivider)`
  margin: 0 0rem;
`;

export const Title = styled(BaseTypography.Text)`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const SubTitle = styled(BaseTypography.Text)`
  font-size: 0.775rem;
  font-weight: 500;
  margin-left: 8px;
  opacity: 0.5;
`;

export const Description = styled(BaseTypography.Text)`
  font-size: 0.875rem;
`;
