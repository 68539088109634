import { fetchPlans } from '@app/api/subscriptions.api';
import { SubscriptionPlan } from '@app/domain/SubscriptionPlan';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  plans: SubscriptionPlan[];
}

const initialState: InitialState = {
  plans: [],
};

export const getSubscriptionPlan = createAsyncThunk('subscriptions/getSubscriptionPlan', async () => {
  try {
    const res = await fetchPlans();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionPlan.fulfilled, (state, action) => {
      state.plans = action.payload;
    });
  },
});

export default subscriptionSlice.reducer;
