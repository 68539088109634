import { Link } from 'react-router-dom';
import { BaseAlert } from '../common/BaseAlert/BaseAlert';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseRow } from '../common/BaseRow/BaseRow';

const AgentDashboard = () => {
  return (
    <BaseRow gutter={[30, 30]}>
      <BaseCol md={24} sm={24}>
        <BaseAlert
          showIcon
          type="info"
          message="Subscriptions & Billing"
          description="You are in the Start up plan, upgrade it to get more benefits."
          action={[
            <BaseButton key="1" type="ghost">
              <Link to={'/pricing-plans'}>Upgrade</Link>
            </BaseButton>,
          ]}
        />
      </BaseCol>
    </BaseRow>
  );
};

export default AgentDashboard;
