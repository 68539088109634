import { Agency } from '@app/domain/Agency';
import { httpApi } from './http.api';
import { InviteFormValues } from '@app/components/agent-dashboard/agency/AddAgent';

export const fetchAgencyInfo = (): Promise<Agency> => httpApi.get<Agency>('agencies').then(({ data }) => data);

export const fetchSharedAgencyList = (): Promise<Agency[]> =>
  httpApi.get<Agency[]>('profile/agencies').then(({ data }) => data);

export const revokeAgencySharing = (agencyId?: number): Promise<boolean> =>
  httpApi.get<boolean>(`profile/revoke/${agencyId ?? 'all'}`).then(({ data }) => data);

export const updateAgencyInfo = (agency: Agency): Promise<Agency> =>
  httpApi.put<Agency>(`agencies/${agency.id}`, { ...agency }).then(({ data }) => data);

export const inviteAgent = (agent: InviteFormValues): Promise<boolean> =>
  httpApi.post(`users/staff`, { ...agent }).then(({ data }) => data);
