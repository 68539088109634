import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';
import { LAYOUT } from '@app/styles/themes/constants';
import { media } from '@app/styles/themes/constants';
import styled, { css } from 'styled-components';

interface Header {
  $isTwoColumnsLayoutHeader: boolean;
}

export const Header = styled(BaseLayout.Header)<Header>`
  line-height: 1.5;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(41, 41, 41, 0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(41, 41, 41, 0.25);
  box-shadow: 0px 0px 5px 0px rgba(41, 41, 41, 0.25);
  @media only screen and ${media.md} {
    // padding: 0 ${LAYOUT.desktop.paddingHorizontal};
    height: ${LAYOUT.desktop.headerHeight};
    margin-bottom: 1px;
  }

  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`;
