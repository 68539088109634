import { Buyer } from '@app/domain/Buyer';
import { httpApi } from '../http.api';
import { Viewing } from '@app/domain/Viewing';

// export const fetchLettingsRequest = (): Promise<LettingsRequest[]> =>
//   httpApi.get<LettingsRequest[]>(`requests/sale`).then(({ data }) => data);

export const fetchSalesBuyers = (): Promise<Buyer[]> => httpApi.get<Buyer[]>(`profiles/sale`).then(({ data }) => data);

export const fetchSalesViewings = (): Promise<Viewing[]> =>
  httpApi.get<Viewing[]>(`viewings/sales/soon`).then(({ data }) => data);

// export const fetchSalesOffers = (): Promise<LettingApplication[]> =>
//   httpApi.get<LettingApplication[]>(`applicants/sale`).then(({ data }) => data);
