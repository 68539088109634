import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import agencyReducer from '@app/store/slices/agencySlice';
import activityReducer from '@app/store/slices/activitySlice';
import searchReducer from '@app/store/slices/searchSlice';
import lettingReducer from '@app/store/slices/lettingSlice';
import propertyReducer from '@app/store/slices/propertySlice';
import subscriptionReducer from '@app/store/slices/subscriptionSlice';
import salesReducer from '@app/store/slices/salesSlice';
import landlordReducer from '@app/store/slices/landlordSlice';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  agency: agencyReducer,
  activities: activityReducer,
  searches: searchReducer,
  lettings: lettingReducer,
  properties: propertyReducer,
  subscriptions: subscriptionReducer,
  sales: salesReducer,
  landlord: landlordReducer,
};
