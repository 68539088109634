import {
  DollarOutlined,
  FileOutlined,
  HistoryOutlined,
  IdcardOutlined,
  SecurityScanOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';
import { UserRole } from './enums/userRoles';

export interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success';
  href: string;
  roles: UserRole[];
}

export const profileNavData: ProfileNavItem[] = [
  {
    id: 1,
    name: 'profile.nav.profileSummary.title',
    icon: <IdcardOutlined />,
    color: 'primary',
    href: 'summary',
    roles: [UserRole.RENTER],
  },
  {
    id: 2,
    name: 'profile.nav.personalInfo.title',
    icon: <UserOutlined />,
    color: 'primary',
    href: 'personal-info',
    roles: [UserRole.AGENCY_ADMIN, UserRole.RENTER],
  },
  {
    id: 4,
    name: 'profile.nav.finances.title',
    icon: <DollarOutlined />,
    color: 'primary',
    href: 'finances',
    roles: [UserRole.RENTER],
  },
  {
    id: 5,
    name: 'profile.nav.rentingHistory.title',
    icon: <HistoryOutlined />,
    color: 'primary',
    href: 'renting-history',
    roles: [UserRole.RENTER],
  },
  {
    id: 7,
    name: 'profile.nav.uploadedFiles.title',
    icon: <FileOutlined />,
    color: 'primary',
    href: 'uploaded-files',
    roles: [UserRole.RENTER],
  },
  {
    id: 6,
    name: 'profile.nav.references.title',
    icon: <TeamOutlined />,
    color: 'primary',
    href: 'references',
    roles: [UserRole.RENTER],
  },
  {
    id: 3,
    name: 'profile.nav.securitySettings.title',
    icon: <SecurityScanOutlined />,
    color: 'primary',
    href: 'security-settings',
    roles: [UserRole.AGENCY_ADMIN, UserRole.RENTER],
  },
];
