import { fetchEmailTemplates, fetchLandlordInfo, fetchLandlordMessages } from '@app/api/crm/landlords.api';
import { EmailTemplate } from '@app/domain/EmailTemplate';
import { LandlordMessage } from '@app/domain/LandlordMessage';
import { Landlord, Landlords } from '@app/domain/crm/Landlord';
import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  landlords: Landlords | null;
  messages: LandlordMessage[];
  landlordInfo: Landlord | null;
  emailTemplates: EmailTemplate[];
}

const initialState: InitialState = {
  landlords: null,
  messages: [],
  emailTemplates: [],
  landlordInfo: null,
};

export const setLandlords = createAction<PrepareAction<Landlords>>('landlords/setLandlords', (data) => {
  return {
    payload: data,
  };
});

export const getLandlordInfo = createAsyncThunk('landlords/getLandlordInfo', async (landLordId: number) => {
  try {
    const res = await fetchLandlordInfo(landLordId);
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getEmailTemplates = createAsyncThunk('landlords/getEmailTemplates', async () => {
  try {
    const res = await fetchEmailTemplates();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getMessages = createAsyncThunk('landlords/getMessages', async (landLordId: number) => {
  try {
    const res = await fetchLandlordMessages(landLordId);
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const landlordSlice = createSlice({
  name: 'landlords',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLandlords, (state, action) => {
      state.landlords = action.payload;
    });
    builder.addCase(getEmailTemplates.fulfilled, (state, action) => {
      state.emailTemplates = action.payload;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.messages = action.payload;
    });
    builder.addCase(getLandlordInfo.fulfilled, (state, action) => {
      state.landlordInfo = action.payload;
    });
  },
});

export default landlordSlice.reducer;
