import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseModal } from '../../common/BaseModal/BaseModal';
import { useCallback, useEffect, useState } from 'react';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import styled from 'styled-components';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import CustomSelectOption from './CustomSelectOption';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Property } from '@app/domain/Property';
import { fetchLeadsForProperty, fetchProperties } from '@app/api/properties/properties.api';
import { Profile } from '@app/domain/Profile';
import { fetchProfiles } from '@app/api/profile/profile.api';
import { BaseDatePicker } from '@app/components/common/pickers/BaseDatePicker';
import { Button } from 'antd';
import { PropertyLead } from '@app/domain/PropertyLead';
import { BaseAlert } from '@app/components/common/BaseAlert/BaseAlert';
import { BroadcastViewingRequest, BroadcastViewingResponse } from '@app/domain/BroadcastViewing';
import { addViewing } from '@app/api/viewings/viewings.api';
import { notificationController } from '@app/controllers/notificationController';
import dayjs from 'dayjs';
import { useMounted } from '@app/hooks/useMounted';

interface BroadcastViewingModalProps {
  open: boolean;
  onClose: () => void;
  onSaved: (response: BroadcastViewingResponse, transType: number) => void;
}

const BroadcastViewingModal = (props: BroadcastViewingModalProps) => {
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState<{ data: Property[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [guests, setGuests] = useState<{ data: Profile[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [propertyLeads, setPropertyLeads] = useState<{ data: PropertyLead | null; loading: boolean }>({
    data: null,
    loading: false,
  });
  const [, setFormedChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted && props.open) {
      loadProperties();
      loadGuests();
    }
  }, [isMounted, props.open]);

  const loadProperties = async () => {
    setProperties({ data: [], loading: true });
    const res = await fetchProperties();
    setProperties({ data: res, loading: false });
  };

  const loadGuests = async () => {
    setGuests({ data: [], loading: true });
    const res = await fetchProfiles();
    setGuests({ data: res, loading: false });
  };

  const handleSave = () => {
    form.submit();
  };

  const onFinish = useCallback(
    async (fieldsValue: any) => {
      const viewingDate = fieldsValue['start'].format('YYYY-MM-DD HH:mm:ss');
      const values: BroadcastViewingRequest = {
        ...fieldsValue,
        start: viewingDate,
        contacts: fieldsValue['contacts'] ?? [],
        guests: fieldsValue['guests'] ?? [],
      };
      setLoading(true);
      const res = await addViewing(values);
      setLoading(false);
      props.onSaved(
        res,
        properties.data.find((property) => property.id === parseInt(fieldsValue['property_id']))?.data.trans_type_id ??
          0,
      );
      form.resetFields();
      notificationController.success({ message: 'Viewing added successfully', placement: 'bottomRight' });
    },
    [props],
  );

  const getPropertyTitle = (property?: Property) => {
    return `${property?.address_line_1 ?? ''} ${property?.address_line_2 ?? ''}`;
  };

  const getPropertySubTitle = (property?: Property) => {
    return `${[property?.address_line_3 ?? '']} ${property?.address_line_4 ?? ''} ${property?.area ?? ''}`;
  };

  const loadPropertyLeads = async (propertyId: string) => {
    setPropertyLeads({ data: null, loading: true });
    const res = await fetchLeadsForProperty(parseInt(propertyId));
    setPropertyLeads({ data: res, loading: false });
  };

  const handleAddAll = () => {
    const addValues =
      form.getFieldValue('online') === '2'
        ? propertyLeads.data?.data?.map((lead) => lead.id)
        : guests.data?.map((guest) => guest.id);

    if (form.getFieldValue('online') === '2') {
      form.setFieldsValue({ contacts: addValues ?? [] });
    } else {
      form.setFieldsValue({ guests: addValues ?? [] });
    }
  };

  const handleClearAll = () => {
    if (form.getFieldValue('online') === '2') {
      form.setFieldsValue({ contacts: [] });
    } else {
      form.setFieldsValue({ guests: [] });
    }
  };

  const handleClose = () => {
    props.onClose();
    form.resetFields();
  };

  return (
    <BaseModal
      maskClosable={false}
      closable={false}
      title="Broadcast Viewing"
      centered
      open={props.open}
      onCancel={props.onClose}
      footer={[
        <Button size="small" key="back" onClick={handleClose} style={{ fontSize: 12 }}>
          Close
        </Button>,
        <Button
          loading={loading}
          size="small"
          key="submit"
          type="primary"
          onClick={handleSave}
          style={{ fontSize: 12 }}
        >
          Add Viewing
        </Button>,
      ]}
    >
      <BaseButtonsForm
        form={form}
        onValuesChange={() => setFormedChanged((prev) => !prev)}
        name="broadcastViewingForm"
        loading={loading}
        isFieldsChanged={false}
        onFinish={onFinish}
      >
        <BaseRow gutter={[30, 10]}>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name="property_id"
              label={'Properties'}
              $padding="0 0 0px"
            >
              <PropertySelect
                loading={properties.loading}
                width={'100%'}
                placeholder={
                  <CustomSelectOption
                    avatarShape="square"
                    title="Select a property"
                    description="Click here to see a list of properties"
                  />
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                onChange={(value: string) => loadPropertyLeads(value)}
              >
                {properties?.data?.map((property) => {
                  return (
                    <Option key={property.id} value={property.id}>
                      <CustomSelectOption
                        avatarShape="square"
                        title={`${getPropertyTitle(property)}`}
                        description={`${getPropertySubTitle(property) ?? ''}`}
                      />
                    </Option>
                  );
                })}
              </PropertySelect>
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name="online"
              label={'Viewing type'}
              $padding="0 0 0px"
            >
              <BaseSelect placeholder="Select a viewing type">
                <Option value="2">{`Agency's side online viewing`}</Option>
                <Option value="1">{`Online viewing`}</Option>
                <Option value="0">{`Property viewing`}</Option>
              </BaseSelect>
            </BaseButtonsForm.Item>
          </BaseCol>

          {form.getFieldValue('online') === '2' && (
            <BaseCol xs={24} md={24}>
              <BaseAlert
                type="success"
                message="This option will send an email with the date, the time and the link of the live viewing in your agency's site to the contacts selected."
              />
            </BaseCol>
          )}

          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name="start"
              label={'Viewing date'}
              $padding="0 0 0px"
            >
              <BaseDatePicker
                format={'YYYY-MM-DD HH:mm:ss'}
                placeholder="Select viewing date"
                showTime={{ format: 'hh:mm:ss A' }}
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  const currentDate = dayjs().startOf('day'); // Get the current date without time
                  return currentDate.isAfter(current) || currentDate.add(1, 'month').isBefore(current);
                }}
              />
            </BaseButtonsForm.Item>
          </BaseCol>
          {form.getFieldValue('online') === '2' ? (
            <BaseCol xs={24} md={24}>
              <BaseButtonsForm.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="contacts"
                label={'Contacts'}
                $padding="0 0 0px"
              >
                <BaseSelect
                  allowClear
                  maxTagCount="responsive"
                  mode="multiple"
                  placeholder="Select contact to send email..."
                  loading={propertyLeads.loading}
                >
                  {propertyLeads?.data?.data?.map((property) => {
                    return (
                      <Option
                        key={property.id}
                        value={property.id}
                      >{`${property.contacts.first_name} ${property.contacts.last_name}`}</Option>
                    );
                  })}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          ) : (
            <BaseCol xs={24} md={24}>
              <BaseButtonsForm.Item
                rules={[
                  {
                    required: true,
                  },
                ]}
                name="guests"
                label={'Guests'}
                $padding="0 0 0px"
              >
                <BaseSelect allowClear maxTagCount="responsive" mode="multiple" placeholder="Select guests...">
                  {guests?.data?.map((guest) => {
                    return <Option key={guest.id} value={guest.id}>{`${guest.first_name} ${guest.last_name}`}</Option>;
                  })}
                </BaseSelect>
              </BaseButtonsForm.Item>
            </BaseCol>
          )}
          <BaseCol xs={24} md={24}>
            <BaseRow justify={'end'}>
              <Button onClick={handleAddAll} style={{ fontSize: 12 }} size="small" type="ghost">{`Add All (${
                form.getFieldValue('online') === '2' ? propertyLeads.data?.count ?? 0 : guests.data.length ?? 0
              })`}</Button>
              <Button
                onClick={handleClearAll}
                size="small"
                style={{ marginLeft: 16, fontSize: 12 }}
                danger
                type="ghost"
              >
                Clear All
              </Button>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </BaseModal>
  );
};

export default BroadcastViewingModal;

const PropertySelect = styled(BaseSelect)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 80px;
  }
`;
