import { HomeOutlined } from '@ant-design/icons';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { List } from 'antd';
import styled from 'styled-components';

interface CustomSelectOptionProps {
  title: string;
  description?: string;
  avatarShape?: 'circle' | 'square';
}

const CustomSelectOption = (props: CustomSelectOptionProps) => {
  return (
    <List.Item>
      <ListMeta
        avatar={
          <BaseAvatar
            style={{ backgroundColor: 'var(--primary-color)' }}
            shape={props.avatarShape ?? 'circle'}
            icon={<HomeOutlined />}
          />
        }
        title={props.title}
        description={props.description}
      />
    </List.Item>
  );
};

export default CustomSelectOption;

const ListMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-description {
    color: var(--textMain);
    font-size: 14px;
    line-height: 1.5715;
  }
`;
