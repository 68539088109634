export enum UserClass {
  CLASS_APPLICANT = 1,
  CLASS_CORENTER = 2,
  CLASS_PROFILE = 3,
  CLASS_PROPERTY = 4,
  CLASS_REQUEST = 5,
  CLASS_VIEWING = 6,
  CLASS_MATCH = 7,
  CLASS_LEAD = 8,
  CLASS_PRICING_SUBSCRIPTION = 9,
  CLASS_PAYMENT = 10,
  CLASS_SELLER = 11,
}
