import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Search } from '@app/domain/UserModel';
import { searchesProperties } from '@app/api/properties/properties.api';
import { searchesProfile } from '@app/api/profile/profile.api';

export interface InitialState {
  searchData: Search[];
  searchResults: Search[];
}

const initialState: InitialState = {
  searchData: [],
  searchResults: [],
};

export const setSearchFormData = createAction<PrepareAction<Search[]>>(
  'searches/setSearchFormData',
  (searchFormData) => {
    return {
      payload: searchFormData,
    };
  },
);

export const setSearchResults = createAction<PrepareAction<Search[]>>('searches/setSearchResults', (result) => {
  return {
    payload: result,
  };
});

export const fetchSearchFormData = createAsyncThunk('searches/fetchSearchFormData', async (_data, { dispatch }) => {
  try {
    const res = await searchesProfile();
    dispatch(setSearchFormData(res));
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const doSearch = createAsyncThunk('searches/doSearch', async (data: Search, { dispatch }) => {
  try {
    const res = await searchesProperties(data);
    dispatch(setSearchResults(res));
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const searchSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSearchFormData.fulfilled, (state, action) => {
      state.searchData = action.payload;
    });

    builder.addCase(doSearch.fulfilled, (state, action) => {
      state.searchResults = action.payload;
    });
  },
});

export default searchSlice.reducer;
