import React, { useEffect } from 'react';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { BaseDatePicker } from '../common/pickers/BaseDatePicker';
import { BaseSelect, Option } from '../common/selects/BaseSelect/BaseSelect';
import { Search } from '@app/domain/UserModel';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber.styles';
import styled from 'styled-components';
import { media } from '@app/styles/themes/constants';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { BaseCard } from '../common/BaseCard/BaseCard';
import dayjs from 'dayjs';
import { FormInstance } from 'antd';
import { DateFormat } from '@app/constants/enums/dateFormat';

interface DashboardSearchCardProps {
  isLoading: boolean;
  onFinish: (values: Search) => void;
  form: FormInstance;
  searchFormValues: Search | undefined;
}

export const DashboardSearchCard: React.FC<DashboardSearchCardProps> = (props) => {
  useEffect(() => {
    if (props.searchFormValues) {
      const updatedValues = {
        trans_type_id: props.searchFormValues?.trans_type_id,
        place: props.searchFormValues?.place,
        radius: props.searchFormValues?.radius,
        beds: props.searchFormValues?.beds,
        budget: props.searchFormValues?.budget,
        moving_date: dayjs(props.searchFormValues?.move_date, DateFormat.YYYY_MM_DD),
      };
      // Set the updated values in the form
      props.form.setFieldsValue(updatedValues);
    }
  }, [props.form, props.searchFormValues]);

  return (
    <FilterWrapper bodyStyle={{ padding: 16 }}>
      <BaseButtonsForm
        form={props.form}
        name="search"
        loading={props.isLoading}
        initialValues={props.searchFormValues}
        isFieldsChanged={false}
        onFinish={props.onFinish}
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item
              name="trans_type_id"
              label="Select Type"
              style={{ marginBottom: 4 }}
              $padding="0 0 0px"
            >
              <DropdownSelect>
                <Option value={2}>RENT</Option>
                <Option value={1}>BUY</Option>
              </DropdownSelect>
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item
              name="place"
              label="Where are you looking to live"
              style={{ marginBottom: 4 }}
              $padding="0 0 0px"
            >
              <BaseInput style={{ textAlign: 'center' }} placeholder="Where are you looking to live..." />
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item name="radius" label="Area in miles" style={{ marginBottom: 4 }} $padding="0 0 0px">
              <InputNumber placeholder="Area in miles" min={1} $block max={10} />
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item name="beds" label="Property Type" style={{ marginBottom: 4 }} $padding="0 0 0px">
              <DropdownSelect placeholder="Select Property Type">
                <Option value={1}>Studio</Option>
                <Option value={1}>1 Bedroom</Option>
                <Option value={2}>2 Bedrooms</Option>
                <Option value={3}>3 Bedrooms</Option>
                <Option value={4}>4 Bedrooms</Option>
                <Option value={5}>5 Bedrooms</Option>
              </DropdownSelect>
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item $padding="0 0 0px" name="budget" label="Max Budget" style={{ marginBottom: 4 }}>
              <InputNumber $block placeholder="Max budget" />
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.Item name="moving_date" label="Move in date" $padding="0 0 0px">
              <DatePicker format={DateFormat.YYYY_MM_DD} placeholder="Move in date" style={{ width: '100%' }} />
            </BaseButtonsForm.Item>
          </BaseCol>
          <BaseCol xs={24} md={24}>
            <BaseButton
              loading={props.isLoading}
              onClick={() => props.form.submit()}
              style={{ width: '100%' }}
              severity="success"
            >
              Save Search
            </BaseButton>
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </FilterWrapper>
  );
};

const DatePicker = styled(BaseDatePicker)`
  .ant-picker-input > input {
    text-align: center !important;
  }
`;

export const FilterWrapper = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  height: min-content;

  @media only screen and ${media.md} {
    position: sticky;
    margin-right: 20px;
  }
`;

const DropdownSelect = styled(BaseSelect)`
  .ant-select-selection-item {
    text-align: center;
  }
`;
