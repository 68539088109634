import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { FONT_WEIGHT, FONT_SIZE } from '@app/styles/themes/constants';

export const Input = styled(AntInput)`
  .ant-input-group-addon:first-child,
  .ant-input-group-addon:last-child {
    min-width: 5.5rem;
    color: var(--primary-color);
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.md};
  }

  .ant-input {
    padding: 8px 11px;
  }

  .ant-input-group-addon .ant-select {
    .ant-select-selection-item {
      min-width: 5.5rem;
      color: var(--primary-color);
      font-weight: ${FONT_WEIGHT.semibold};
      font-size: ${FONT_SIZE.xs};
    }
  }

  .ant-select-arrow {
    color: var(--disabled-color);
  }

  &::placeholder {
    color: var(--text-main-color);
    opacity: 0.5;
  }

  &.ant-select-selection-placeholder {
    color: var(--text-main-color);
    opacity: 0.5 !important;
  }
`;
