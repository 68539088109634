import styled from 'styled-components';
import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { BaseDivider } from '@app/components/common/BaseDivider/BaseDivider';

export const Text = styled(BaseTypography.Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: ${FONT_SIZE.xs};
  font-weight: 600;

  & > a {
    display: block;
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.md};
  }
`;

export const ItemsDivider = styled(BaseDivider)`
  margin: 0;
`;
