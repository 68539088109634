import { httpApi } from '../http.api';
import { LettingsRequest } from '@app/domain/lettings/LettingsRequest';
import { LettingsRenter } from '@app/domain/lettings/LettingsRenter';
import { LettingApplication } from '@app/domain/lettings/LettingApplication';
import { Viewing } from '@app/domain/Viewing';

export const fetchLettingsRequest = (): Promise<LettingsRequest[]> =>
  httpApi.get<LettingsRequest[]>(`requests/letting`).then(({ data }) => data);

export const fetchLettingsRenter = (): Promise<LettingsRenter[]> =>
  httpApi.get<LettingsRenter[]>(`profiles/letting`).then(({ data }) => data);

export const fetchLettingsViewing = (): Promise<Viewing[]> =>
  httpApi.get<Viewing[]>(`viewings/lettings/soon`).then(({ data }) => data);

export const fetchLettingsApplications = (): Promise<LettingApplication[]> =>
  httpApi.get<LettingApplication[]>(`applicants/letting`).then(({ data }) => data);
