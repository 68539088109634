import React, { useState } from 'react';
import { NotificationsDropdown } from '../notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../profileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { MessageOutlined, SyncOutlined, UserAddOutlined } from '@ant-design/icons';
import { HeaderActionWrapper } from '../Header.styles';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { UserRole } from '@app/constants/enums/userRoles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
import { Popconfirm } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { pullProperties } from '@app/api/properties/properties.api';
import AddEditContactModal from '@app/components/agent-dashboard/contacts/AddConact/AddEditContactModal';
import BroadcastViewingModal from '@app/components/agent-dashboard/viewing/BroadcastViewingModal';
import { useNavigate } from 'react-router-dom';
import { BroadcastViewingResponse } from '@app/domain/BroadcastViewing';
interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const [openAddContactDialog, setOpenAddContactDialog] = useState<boolean>(false);
  const [openBroadcastViewingDialog, setBroadcastViewingDialog] = useState<boolean>(false);

  const isAgencyAdmin = () => {
    return user?.role === UserRole.AGENCY_ADMIN;
  };

  const pullPropertiesFromDaft = async () => {
    setLoading(true);
    const res = await pullProperties();
    setLoading(false);
    notificationController.success({ message: res.message, placement: 'bottomRight' });
  };

  const handleOnSaveBroadcastView = (response: BroadcastViewingResponse, transType: number) => {
    setBroadcastViewingDialog(false);
    if (response.online === '1') {
      navigate('/online-viewing-map');
    } else {
      navigate(`/${transType === 1 ? 'sales' : 'lettings'}`, { state: { selectedTab: 3 } });
    }
  };

  const leftSide = (
    <S.SearchColumn xl={4} xxl={8}>
      <BaseRow align="middle" justify="start" gutter={[5, 5]}>
        <BaseCol>
          <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}></BaseRow>
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  );

  return (
    <>
      <BaseRow justify="space-between" align="middle">
        {leftSide}

        <S.ProfileColumn xl={20} xxl={16} $isTwoColumnsLayout={isTwoColumnsLayout}>
          <BaseRow align="middle" justify="end" gutter={[5, 5]}>
            <BaseCol>
              <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
                <BaseCol>
                  <HeaderFullscreen />
                </BaseCol>

                {isAgencyAdmin() && (
                  <>
                    <BaseCol>
                      <HeaderActionWrapper>
                        <BaseTooltip placement="bottom" title={'Pull properties from daft'}>
                          <Popconfirm title="Pull the properties from Daft.ie?" onConfirm={pullPropertiesFromDaft}>
                            <BaseButton type={'text'} icon={<SyncOutlined spin={isLoading} />} />
                          </Popconfirm>
                        </BaseTooltip>
                      </HeaderActionWrapper>
                    </BaseCol>

                    <BaseCol>
                      <HeaderActionWrapper>
                        <BaseTooltip placement="bottom" title={'Add Contact'}>
                          <BaseButton
                            type={'text'}
                            icon={<UserAddOutlined />}
                            onClick={() => setOpenAddContactDialog(true)}
                          />
                        </BaseTooltip>
                      </HeaderActionWrapper>
                    </BaseCol>

                    <BaseCol>
                      <HeaderActionWrapper>
                        <BaseTooltip placement="bottom" title={'Broadcast a viewing'}>
                          <BaseButton type={'text'} onClick={() => setBroadcastViewingDialog(true)}>
                            <FontAwesomeIcon icon={faTowerBroadcast} />
                          </BaseButton>
                        </BaseTooltip>
                      </HeaderActionWrapper>
                    </BaseCol>
                    {/* <BaseCol>
                      <HeaderActionWrapper>
                        <BaseTooltip placement="bottom" title={'Messages'}>
                          <BaseButton type={'text'} icon={<MessageOutlined />} />
                        </BaseTooltip>
                      </HeaderActionWrapper>
                    </BaseCol> */}
                  </>
                )}

                <BaseCol>
                  <NotificationsDropdown />
                </BaseCol>

                <BaseCol>
                  <SettingsDropdown />
                </BaseCol>
              </BaseRow>
            </BaseCol>

            <BaseCol>
              <ProfileDropdown />
            </BaseCol>
          </BaseRow>
        </S.ProfileColumn>
      </BaseRow>

      <AddEditContactModal
        open={openAddContactDialog}
        onClose={() => setOpenAddContactDialog(false)}
        onSave={() => {
          setOpenAddContactDialog(false);
        }}
      />

      <BroadcastViewingModal
        open={openBroadcastViewingDialog}
        onSaved={handleOnSaveBroadcastView}
        onClose={() => {
          setBroadcastViewingDialog(false);
        }}
      />
    </>
  );
};
