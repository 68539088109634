import { UserModel } from '@app/domain/UserModel';
import { StorageKey } from './storageKey';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string | null => {
  return localStorage.getItem('accessToken') || null;
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return JSON.parse(userStr || '{}');
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');

export const persistData = (data: unknown, key: StorageKey): void => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const readData = (key: StorageKey): unknown | null => {
  const str = localStorage.getItem(key);

  return JSON.parse(str || '{}');
};
