import { Contact, ContactList } from '@app/domain/Contact';
import { httpApi } from '../http.api';
import { ContactFormValues } from '@app/components/agent-dashboard/contacts/AddConact/AddEditContactModal';
import { Pagination, QueryParam } from '@app/interfaces/interfaces';

export const fetchContacts = (path: string, pagination: Pagination, queryParams?: QueryParam): Promise<ContactList> =>
  httpApi.get<ContactList>(`${path}`, { params: { ...queryParams, ...pagination } }).then(({ data }) => data);

export const fetchContact = (contactId: number): Promise<Contact> =>
  httpApi.get<Contact>(`contacts/${contactId}`).then(({ data }) => data);

export const inviteContact = (contactId: number): Promise<unknown> =>
  httpApi.get(`contacts/${contactId}/invite`).then(({ data }) => data);

export const updateContact = (contact: ContactFormValues): Promise<void> =>
  httpApi.put(`contacts/${contact.id}`, { ...contact }).then(({ data }) => data);

export const addContact = (contact: ContactFormValues): Promise<unknown> =>
  httpApi.post(`contacts`, { ...contact }).then(({ data }) => data);

export const deleteContact = (contactId: number): Promise<boolean> =>
  httpApi.delete(`contacts/${contactId}`).then(({ data }) => data);

export const fetchContactByCoordinates = (lat: string, lon: string): Promise<Contact[]> =>
  httpApi.post<Contact[]>(`contacts/get-by-coords`, { latitude: lat, longitude: lon }).then(({ data }) => data);
