import { InputNumber as AntInputNumber } from 'antd';
import styled from 'styled-components';

interface InputNumberProps {
  $block?: boolean;
}

export const InputNumber = styled(AntInputNumber)<InputNumberProps>`
  .ant-input-number-input {
    text-align: center;
    height: 40px;
  }
  .ant-input {
    padding: 8px 11px;
  }
  ${(props) => props.$block && 'display: block; width: 100%;'};

  &::placeholder {
    color: var(--text-main-color);
    opacity: 0.5;
  }
  .ant-select-selection-placeholder {
    color: var(--text-main-color);
    opacity: 0.5 !important;
  }
`;
