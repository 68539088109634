import React from 'react';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  EyeOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  ShareAltOutlined,
  WarningFilled,
} from '@ant-design/icons';
import * as S from './BaseNotification.styles';
import { BaseSpace } from '../BaseSpace/BaseSpace';
import { BaseRow } from '../BaseRow/BaseRow';
import { BaseButton } from '../BaseButton/BaseButton';
import { BasePopover } from '../BasePopover/BasePopover';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { markAsReadActivity } from '@app/api/activity.api';
import { getActivities } from '@app/store/slices/activitySlice';

interface Icons {
  info: React.ReactNode;
  success: React.ReactNode;
  warning: React.ReactNode;
  error: React.ReactNode;
  share: React.ReactNode;
}

export type NotificationType = 'info' | 'share' | 'success' | 'warning' | 'error';

interface BaseNotificationProps {
  activityId: number;
  type: NotificationType;
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  mentionIconSrc?: React.ReactNode;
}

export const BaseNotification: React.FC<BaseNotificationProps> = ({
  activityId,
  type,
  mentionIconSrc,
  title,
  subTitle,
  description,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const icons: Icons = {
    info: <InfoCircleFilled />,
    success: <CheckCircleFilled />,
    warning: <ExclamationCircleFilled />,
    error: <WarningFilled />,
    share: <ShareAltOutlined />,
  };

  const icon = icons[type] || icons.warning;

  const handleMarkAsRead = async () => {
    setLoading(true);
    await markAsReadActivity(activityId);
    dispatch(getActivities()).then(() => setLoading(false));
  };

  return (
    <S.SpaceWrapper type={type} align="start" size="middle">
      {mentionIconSrc ? <S.NotificationIcon src={icon} alt="User icon" /> : icon}
      <BaseSpace direction="vertical">
        <BaseRow style={{ alignContent: 'center', alignItems: 'center' }}>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{subTitle}</S.SubTitle>
        </BaseRow>
        <S.Description>{description}</S.Description>
      </BaseSpace>
      <BasePopover content="Mark as read">
        <BaseButton
          onClick={handleMarkAsRead}
          icon={
            loading ? <LoadingOutlined style={{ fontSize: '1rem' }} /> : <EyeOutlined style={{ fontSize: '1rem' }} />
          }
          type="link"
          size="small"
        />
      </BasePopover>
    </S.SpaceWrapper>
  );
};
