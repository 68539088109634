import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Activity } from '@app/domain/Activity';
import { getUserActivities } from '@app/api/activity.api';

export interface InitialState {
  activities: Activity[];
}

const initialState: InitialState = {
  activities: [],
};

export const getActivities = createAsyncThunk('activities/getActivities', async () => {
  try {
    const res = await getUserActivities();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const activitySlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivities.fulfilled, (state, action) => {
      state.activities = action.payload;
    });
  },
});

export default activitySlice.reducer;
