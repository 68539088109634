import { Property } from '@app/domain/Property';
import { httpApi } from '../http.api';
import { Search } from '@app/domain/UserModel';
import { PropertyLead } from '@app/domain/PropertyLead';
import { PropertyMatch } from '@app/domain/PropertyMatch';
import { PropertyOwner } from '@app/domain/PropertyOwner';
import { AddPropertyForm } from '@app/components/agent-dashboard/properties/AddProperty';

interface Response {
  error: boolean;
  message: string;
}

export const pullProperties = (): Promise<Response> =>
  httpApi.get<Response>('pull-properties').then(({ data }) => data);

export const fetchProperties = (): Promise<Property[]> =>
  httpApi.get<Property[]>('properties').then(({ data }) => data);

export const searchesProperties = (data: Search): Promise<Search[]> =>
  httpApi.post<Search[]>('searches', { ...data }).then(({ data }) => data);

export const fetchAllProperties = (): Promise<Property[]> =>
  httpApi.get<Property[]>('properties/get-all').then(({ data }) => data);

export const deleteProperty = (propertyId: number): Promise<boolean> =>
  httpApi.delete<boolean>(`properties/${propertyId}`).then(({ data }) => data);

export const fetchProperty = (propertyId: number): Promise<Property> =>
  httpApi.get<Property>(`properties/${propertyId}`).then(({ data }) => data);

export const fetchMatchesForProperty = (propertyId: number): Promise<PropertyMatch[]> =>
  httpApi.get<PropertyMatch[]>(`properties/${propertyId}/matches`).then(({ data }) => data);

export const fetchLeadsForProperty = (propertyId: number): Promise<PropertyLead> =>
  httpApi.get<PropertyLead>(`properties/${propertyId}/leads`).then(({ data }) => data);

export const fetchLettingsForProperty = (): Promise<Property[]> =>
  httpApi.get<Property[]>(`properties/letting`).then(({ data }) => data);

export const fetchRequestForProperty = (propertyId: number): Promise<Property[]> =>
  httpApi.get<Property[]>(`properties/${propertyId}/requests`).then(({ data }) => data);

export const fetchApplicationsForProperty = (propertyId: number): Promise<Property[]> =>
  httpApi.get<Property[]>(`properties/${propertyId}/applicants`).then(({ data }) => data);

export const fetchViewingsForProperty = (propertyId: number): Promise<Property[]> =>
  httpApi.get<Property[]>(`properties/${propertyId}/viewings`).then(({ data }) => data);

export const fetchAllOwners = (): Promise<PropertyOwner> =>
  httpApi.get<PropertyOwner>(`owners/get/all`).then(({ data }) => data);

export const addProperty = (data: AddPropertyForm): Promise<Property> =>
  httpApi.post<Property>(`properties`, { ...data }).then(({ data }) => data);

export const fetchEstimation = (data: AddPropertyForm): Promise<AddPropertyForm> =>
  httpApi.post<AddPropertyForm>(`properties/estimate`, { ...data }).then(({ data }) => data);
