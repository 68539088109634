import { FONT_SIZE } from '@app/styles/themes/constants';
import { Tooltip } from 'antd';
import styled from 'styled-components';
export type { TooltipProps as BaseTooltipProps } from 'antd';

export const BaseTooltip = styled(Tooltip)`
  .ant-tooltip {
    font-size: ${FONT_SIZE.xs} !important;
  }
`;
