import { BroadcastViewingRequest, BroadcastViewingResponse } from '@app/domain/BroadcastViewing';
import { httpApi } from '../http.api';
import { Viewing } from '@app/domain/Viewing';

export const addViewing = (request: BroadcastViewingRequest): Promise<BroadcastViewingResponse> =>
  httpApi.post<BroadcastViewingResponse>(`viewings`, { ...request }).then(({ data }) => data);

export const fetchLastAddedViewing = (): Promise<Viewing> =>
  httpApi.get<Viewing>(`viewings/last-added`).then(({ data }) => data);

export const deleteViewing = (viewingId: number): Promise<void> =>
  httpApi.delete(`viewings/${viewingId}`).then(({ data }) => data);

export const fetchAllViewing = (): Promise<Viewing[]> =>
  httpApi.get<Viewing[]>(`viewings/all`).then(({ data }) => data);
