import React, { useMemo, useState } from 'react';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { BaseSpace } from '../common/BaseSpace/BaseSpace';
import { SyncOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { BaseEmpty } from '../common/BaseEmpty/BaseEmpty';
import { formatDistanceToNow } from 'date-fns';
import { BaseDivider } from '../common/BaseDivider/BaseDivider';
import styled from 'styled-components';
import { BaseTypography } from '../common/BaseTypography/BaseTypography';
import { acceptPropertyRequest, markAsReadActivity } from '@app/api/activity.api';
import { getActivities } from '@app/store/slices/activitySlice';
import { BaseSkeleton } from '../common/BaseSkeleton/BaseSkeleton';
import { Activity } from '@app/domain/Activity';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../../pages/DashboardPages/DashboardPage.styles';
import { BaseList } from '../common/BaseList/BaseList';
import { UserClass } from '@app/constants/enums/userClass';
import { Property } from '@app/domain/Property';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const ActivityView: React.FC = () => {
  const { isDesktop } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activities = useAppSelector((state) => state.activities.activities);
  const [loading, setLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [markAllAsReadLoading, setMarkAllAsReadLoading] = useState(false);
  const [acceptAllLoading, setAcceptAllLoading] = useState(false);

  const filteredActivities = activities.filter(
    (item) => item.data?.type !== 'userSearchMatches' && item.data?.type !== 'matches',
  );

  const getPropertyTitle = (property?: Property | null) => {
    let title = '';
    if (property?.address_line_1) {
      title = `${property.address_line_1} ${property.area ?? ''} ${property.address_line_4 ?? ''}`;
    } else if (property?.address_line_2) {
      title = `${property.address_line_2 ?? ''} ${property.area ?? ''} ${property.address_line_4 ?? ''}`;
    } else if (property?.address_line_3) {
      title = `${property.address_line_3} ${property.area ?? ''} ${property.address_line_4 ?? ''}`;
    } else if (property?.address_line_4) {
      title = `${property.address_line_4 ?? ''} ${property.area ?? ''}`;
    }
    return title;
  };

  const handleAcceptAllSharing = () => {
    setAcceptAllLoading(true);
    Promise.all(filteredActivities.map((item) => acceptPropertyRequest(item.data.agency?.id ?? 0)));
    setAcceptAllLoading(false);
  };

  const handleMakeAllAsRead = () => {
    setMarkAllAsReadLoading(true);
    Promise.all(filteredActivities.map((item) => markAsReadActivity(item.id)));
    setMarkAllAsReadLoading(false);
  };

  const handleAcceptSharing = async (item: Activity) => {
    setAcceptLoading(true);
    await acceptPropertyRequest(item.data.agency?.id ?? 0);
    setAcceptLoading(false);
  };

  const handleRejectSharing = (item: Activity) => {
    setRejectLoading(true);
  };

  const handleRefresh = async () => {
    setLoading(true);
    dispatch(getActivities())
      .unwrap()
      .then(() => setLoading(false));
  };

  const handlePropertyTitlePress = (property?: Property | null) => {
    if (property) {
      //navigate(`/properties/view`, { state: { id: property.id } });
    }
  };

  const renderSkeletonLoader = useMemo(() => {
    return Array.from({ length: 4 }).map((_, index) => {
      return (
        <div key={index}>
          <BaseSkeleton active paragraph={{ rows: 1 }} />
          <BaseSpace>
            <BaseSkeleton.Button active={true} size="small" shape="round" block={true} />
            <BaseSkeleton.Button active={true} size="small" shape="round" block={true} />
          </BaseSpace>
          <BaseDivider />
        </div>
      );
    });
  }, [loading]);

  const renderNotificationList = useMemo(() => {
    return (
      <BaseList
        dataSource={filteredActivities}
        renderItem={(notification, index) => {
          const title =
            notification.class_id === UserClass.CLASS_MATCH
              ? 'Property Matches'
              : notification.class_id === UserClass.CLASS_REQUEST
              ? 'Profile request'
              : notification.class_id === UserClass.CLASS_VIEWING
              ? 'Viewing Invitation'
              : 'Shared Profile';

          const subTitle =
            notification.class_id === UserClass.CLASS_MATCH ? (
              'Property Matches'
            ) : notification.class_id === UserClass.CLASS_REQUEST ? (
              'Profile request'
            ) : notification.class_id === UserClass.CLASS_VIEWING ? (
              <>
                <a>{notification.data.agency?.name}</a> invited you to the online viewing of{' '}
                <a onClick={() => handlePropertyTitlePress(notification.data.property)}>
                  {getPropertyTitle(notification.data.property)}
                </a>{' '}
                on {moment(notification.data.viewing?.start).format('ddd, DD MMM YYYY @ hh:mm')}
              </>
            ) : (
              'Shared Profile'
            );

          const timeAgoString = formatDistanceToNow(new Date(notification?.created_at), { addSuffix: true });

          return (
            <BaseList.Item
              key={index}
              actions={[
                <Btn
                  key={1}
                  type="ghost"
                  size="small"
                  loading={acceptLoading}
                  onClick={() => handleAcceptSharing(notification)}
                >
                  Accept
                </Btn>,
                <Btn
                  key={2}
                  danger
                  size="small"
                  loading={rejectLoading}
                  type="primary"
                  onClick={() => handleRejectSharing(notification)}
                >
                  Reject
                </Btn>,
              ]}
            >
              <BaseList.Item.Meta
                title={
                  <BaseRow style={{ alignContent: 'center', alignItems: 'center' }}>
                    <Title>{title}</Title>
                    <SubTitle>{timeAgoString}</SubTitle>
                  </BaseRow>
                }
                description={<Description>{subTitle}</Description>}
              />
            </BaseList.Item>
          );
        }}
      />
    );
  }, [acceptLoading, filteredActivities, rejectLoading]);

  return (
    <BaseRow justify={'center'}>
      <BaseCol sm={24} xl={24} md={24}>
        <BaseRow justify={'space-between'}>
          <BaseButton severity="success" loading={loading} icon={<SyncOutlined />} onClick={handleRefresh} />
          <BaseRow style={{ marginTop: !isDesktop ? 16 : 0 }}>
            <BaseSpace>
              <BaseButton
                loading={acceptAllLoading}
                disabled={filteredActivities.length === 0}
                type="ghost"
                onClick={handleAcceptAllSharing}
              >
                Accept all sharing
              </BaseButton>
              <BaseButton
                loading={markAllAsReadLoading}
                disabled={filteredActivities.length === 0}
                type="primary"
                onClick={handleMakeAllAsRead}
              >
                Make all as Read
              </BaseButton>
            </BaseSpace>
          </BaseRow>
        </BaseRow>
      </BaseCol>
      <BaseDivider />

      <BaseCol sm={24} xl={24} md={24}>
        <S.ScrollWrapper>
          {filteredActivities.length > 0 && !loading ? (
            renderNotificationList
          ) : loading ? (
            renderSkeletonLoader
          ) : (
            <BaseEmpty description={'No activity found!'} style={{ marginTop: '20%' }} />
          )}
        </S.ScrollWrapper>
      </BaseCol>
    </BaseRow>
  );
};

export default ActivityView;

export const SplitDivider = styled(BaseDivider)`
  margin: 0 0.5rem;
`;

export const LinkBtn = styled(BaseButton)`
  &.ant-btn {
    padding: 0;
    font-size: 0.875rem;
    height: unset;
    line-height: unset;
  }
`;

export const Btn = styled(BaseButton)`
  width: 60px;
  height: 30px;
`;

export const Title = styled(BaseTypography.Text)`
  font-size: 0.875rem;
  font-weight: 600;
`;

export const SubTitle = styled(BaseTypography.Text)`
  font-size: 0.775rem;
  font-weight: 500;
  margin-left: 8px;
  opacity: 0.5;
`;

export const Description = styled(BaseTypography.Text)`
  font-size: 0.875rem;
`;
