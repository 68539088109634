import { Landlord, Landlords } from '@app/domain/crm/Landlord';
import { httpApi } from '../http.api';
import { Pagination, QueryParam } from '@app/interfaces/interfaces';
import { EmailTemplate, EmailTemplateSaveResponse } from '@app/domain/EmailTemplate';
import { LandlordMessage } from '@app/domain/LandlordMessage';
import { MessageRequest } from '@app/components/agent-dashboard/landlordsAndVendors/Details/Message';
import { InviteRequest } from '@app/components/agent-dashboard/landlordsAndVendors/Details/Invite';
import { CommonResponse } from '@app/domain/CommonResponse';
import { UploadFile } from 'antd';
import { AddOwnerFormRequest } from '@app/components/agent-dashboard/landlordsAndVendors/AddNew/AddOwnerModal';

export const fetchLandlords = (
  endpoint: string,
  pagination: Pagination,
  queryParams?: QueryParam,
): Promise<Landlords> =>
  httpApi
    .get<Landlords>(`owners/get${endpoint}`, { params: { ...queryParams, ...pagination } })
    .then(({ data }) => data);

export const fetchLandlordMessages = (landlordId: number): Promise<LandlordMessage[]> =>
  httpApi.get<LandlordMessage[]>(`owners/message/${landlordId}`).then(({ data }) => data);

export const fetchLandlordInfo = (landlordId: number): Promise<Landlord> =>
  httpApi.get<Landlord>(`owners/info/${landlordId}`).then(({ data }) => data);

export const deleteOwner = (landlordId: number): Promise<CommonResponse> =>
  httpApi.delete<CommonResponse>(`owners/${landlordId}`).then(({ data }) => data);

export const updateLandlordInfo = (requestData: Landlord): Promise<Landlord> =>
  httpApi.post<Landlord>(`owners?id=${requestData.id}`).then(({ data }) => data);

export const fetchEmailTemplates = (): Promise<EmailTemplate[]> =>
  httpApi.get<EmailTemplate[]>(`agencies/email-templates`).then(({ data }) => data);

export const saveEmailTemplates = (template: EmailTemplate): Promise<EmailTemplateSaveResponse> =>
  httpApi.post<EmailTemplateSaveResponse>(`agencies/email-template/save`, { ...template }).then(({ data }) => data);

export const deleteEmailTemplates = (templateId: number): Promise<EmailTemplateSaveResponse> =>
  httpApi.get<EmailTemplateSaveResponse>(`agencies/email-template/delete/${templateId}`).then(({ data }) => data);

export const sendMessage = (request: MessageRequest): Promise<unknown> =>
  httpApi.post(`owners/message/to`, { ...request }).then(({ data }) => data);

export const saveNote = (text: string, landlordId: number): Promise<unknown> =>
  httpApi.post(`owners/${landlordId}/notes`, { text, id: landlordId }).then(({ data }) => data);

export const inviteOwner = (request: InviteRequest): Promise<CommonResponse> =>
  httpApi.post<CommonResponse>(`owners/${request.id}/invite`, { ...request }).then(({ data }) => data);

export const sendEmailToOwner = (request: InviteRequest): Promise<CommonResponse> =>
  httpApi.post<CommonResponse>(`owners/${request.id}/template-email`, { ...request }).then(({ data }) => data);

export const uploadLandlordDocument = async (
  owner_id: number,
  file: UploadFile,
  onUploadProgress: (percentCompleted: number) => void,
): Promise<string> => {
  httpApi.interceptors.request.use((config) => {
    config.headers['Content-Type'] = `multipart/form-data;`;
    config.onUploadProgress = (progressEvent) =>
      onUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1)));
    return config;
  });
  const { data } = await httpApi.post('owners/document', { owner_id: owner_id, file: file });
  return data;
};

export const deleteOwnerDocument = (docId: number): Promise<void> =>
  httpApi.get<void>(`owners/document/${docId}/delete`).then(({ data }) => data);

export const addOwners = (request: AddOwnerFormRequest): Promise<CommonResponse> =>
  httpApi.post<CommonResponse>(`owners`, { ...request }).then(({ data }) => data);
