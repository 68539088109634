import React, { useState } from 'react';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { UserRole } from '@app/constants/enums/userRoles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useDispatch } from 'react-redux';
import { Upload } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload';
import { uploadProfilePicture } from '@app/api/profile/profile.api';
import { setUser } from '@app/store/slices/userSlice';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { LoadingOutlined } from '@ant-design/icons';

export const ProfileInfo: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    if (!isValidateImage(info.file as RcFile)) return;
    setLoading(true);
    const res = await uploadProfilePicture(info.file);
    notificationController.success({ message: 'Profile Image updated successfully!' });
    dispatch(setUser({ ...user, picture: res }));
    setLoading(false);
  };

  const isValidateImage = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;
    return isJpgOrPng && isLt2M;
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notificationController.error({ message: 'You can only upload JPG/PNG file!' });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notificationController.success({ message: 'Image must smaller than 2MB!' });
    }
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handlePlanBtnPress = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleAgentsBtnPress = () => {};

  return user ? (
    <S.Wrapper>
      <Upload
        onChange={handleChange}
        beforeUpload={beforeUpload}
        name="avatar"
        className="avatar-uploader"
        showUploadList={false}
      >
        {loading && <LoadingOutlined />}
        <S.ImgWrapper>
          <BaseAvatar shape="circle" src={user?.picture} alt="Profile" />
        </S.ImgWrapper>
      </Upload>

      <S.Title>{`${user?.first_name} ${user?.last_name}`}</S.Title>
      {user.role === UserRole.AGENCY_ADMIN && (
        <BaseButton onClick={handlePlanBtnPress} size="middle" severity="success">{`${user?.plan}`}</BaseButton>
      )}
      {user.role === UserRole.RENTER && (
        <BaseButton onClick={handleAgentsBtnPress} size="middle" type="primary">{`${
          (user?.searches?.length > 0 && user?.searches[0]?.matches?.length) ?? 'No'
        } Agents`}</BaseButton>
      )}

      {/* {profileData.role === UserRole.RENTER && (
        <S.FullnessWrapper>
          <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
        </S.FullnessWrapper>
      )} */}
    </S.Wrapper>
  ) : null;
};
