import { fetchLettingsApplications, fetchLettingsRenter, fetchLettingsRequest } from '@app/api/lettings/lettings.api';
import { fetchPropertyAreas } from '@app/api/promotions/promotions.api';
import { fetchAllOwners, fetchAllProperties } from '@app/api/properties/properties.api';
import { Property } from '@app/domain/Property';
import { PropertyArea } from '@app/domain/PropertyArea';
import { PropertyLead } from '@app/domain/PropertyLead';
import { PropertyOwner } from '@app/domain/PropertyOwner';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface PropertySlice {
  allProperties: Property[];
  owners: PropertyOwner | null;
  leads: PropertyLead | null;
  lettings: Property[];
  propertyAreas: PropertyArea[];
}

const initialState: PropertySlice = {
  allProperties: [],
  owners: null,
  leads: null,
  lettings: [],
  propertyAreas: [],
};

export const getAllProperties = createAsyncThunk('properties/getAllProperties', async () => {
  try {
    const res = await fetchAllProperties();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getAllPropertyOwners = createAsyncThunk('properties/getAllPropertyOwners', async () => {
  try {
    const res = await fetchAllOwners();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getLettingsRenter = createAsyncThunk('properties/getLettingsRenter', async () => {
  try {
    const res = await fetchLettingsRenter();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getLettingsRequest = createAsyncThunk('properties/getLettingsRequest', async () => {
  try {
    const res = await fetchLettingsRequest();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getLettingsApplication = createAsyncThunk('properties/getLettingsApplication', async () => {
  try {
    const res = await fetchLettingsApplications();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getPropertyAreas = createAsyncThunk('properties/getPropertyAreas', async () => {
  try {
    const res = await fetchPropertyAreas();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

const propertySlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProperties.fulfilled, (state, action) => {
      state.allProperties = action.payload;
    });
    builder.addCase(getAllPropertyOwners.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
    builder.addCase(getPropertyAreas.fulfilled, (state, action) => {
      state.propertyAreas = action.payload;
    });
  },
});

export default propertySlice.reducer;
