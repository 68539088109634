import { PropertyArea } from '@app/domain/PropertyArea';
import { httpApi } from '../http.api';
import { PropertyAnalytics } from '@app/domain/PropertyAnalytics';
import { PropertyPaginatedLead } from '@app/domain/PropertyPaginatedLead';
import { PropertyAnalyticsRequest } from '@app/domain/PropertyAnalyticsRequest';
import { LeadAnalytics } from '@app/domain/promotions/LeadAnalytics';
import { MatchAnalytics } from '@app/domain/promotions/MatchAnalytics';
import { OwnerActivity } from '@app/domain/promotions/OwnerActivity';
import { PromotionalEmail } from '@app/domain/promotions/PromotionalEmail';

export const fetchPropertyAreas = (): Promise<PropertyArea[]> =>
  httpApi.post<PropertyArea[]>(`properties/get-areas`).then(({ data }) => data);

export const fetchPropertyAnalytics = (request: PropertyAnalyticsRequest): Promise<PropertyAnalytics[]> =>
  httpApi.post<PropertyAnalytics[]>(`properties/analytics`, { ...request }).then(({ data }) => data);

export const fetchPropertyMatchesAnalytics = (request: PropertyAnalyticsRequest): Promise<MatchAnalytics> =>
  httpApi.post<MatchAnalytics>(`properties/matches-analytics`, { ...request }).then(({ data }) => data);

export const fetchPropertyPaginatedLeads = (request: PropertyAnalyticsRequest): Promise<PropertyPaginatedLead> =>
  httpApi.post<PropertyPaginatedLead>(`properties/leads-paginated`, { ...request }).then(({ data }) => data);

export const fetchPropertyLeadsAnalytics = (request: PropertyAnalyticsRequest): Promise<LeadAnalytics> =>
  httpApi.post<LeadAnalytics>(`properties/leads-analytics`, { ...request }).then(({ data }) => data);

export const fetchPromotionalEmails = (): Promise<PromotionalEmail> =>
  httpApi.post<PromotionalEmail>(`promotional-emails`, {}).then(({ data }) => data);

export const fetchOwnersAllActivity = (): Promise<OwnerActivity[]> =>
  httpApi.post<OwnerActivity[]>(`owners/all-activity`, {}).then(({ data }) => data);
