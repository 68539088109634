import { addContact, updateContact } from '@app/api/contacts/contact.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { GOOGLE_MAPS_API_KEY } from '@app/constants/config/appConstants';
import { DateFormat } from '@app/constants/enums/dateFormat';
import { notificationController } from '@app/controllers/notificationController';
import { Contact } from '@app/domain/Contact';
import { Autocomplete, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Moment } from 'moment';

interface AddEditContactModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  editableData?: Contact | null;
}

export interface ContactFormValues {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  place: string;
  beds: string;
  radius: string;
  trans_type_id?: string;
  budget: string;
  latitude: string;
  longitude: string;
  invited?: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: null;
  agency_id?: number;
  move_date?: string | Moment;
}

const initialValues: ContactFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  place: '',
  beds: '1',
  radius: '0',
  trans_type_id: undefined,
  budget: '0',
  latitude: '',
  longitude: '',
};

const AddEditContactModal: React.FC<AddEditContactModalProps> = (props: AddEditContactModalProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });
  const [searchResult, setSearchResult] = useState<google.maps.places.Autocomplete>();
  const [latLng, setLatLng] = useState<{ lat: string; lng: string }>({
    lat: '53.350140',
    lng: '-6.266155',
  });

  useEffect(() => {
    if (props.editableData) {
      setLatLng({
        lat: props.editableData.latitude,
        lng: props.editableData.longitude,
      });
    }
  }, [props.editableData, props.open]);

  const formValues = useMemo(
    () =>
      props.editableData
        ? {
            first_name: props.editableData.first_name,
            last_name: props.editableData.last_name,
            email: props.editableData.email,
            phone: props.editableData?.phone,
            place: props.editableData.place,
            beds: props.editableData.beds,
            radius: props.editableData.radius,
            trans_type_id: props.editableData.trans_type_id,
            budget: props.editableData.budget,
            latitude: props.editableData.latitude,
            longitude: props.editableData.longitude,
            move_date: dayjs(props.editableData?.move_date, DateFormat.YYYY_MM_DD),
          }
        : initialValues,
    [props.editableData],
  );

  const handleSave = async () => {
    form.submit();
  };

  const onPlaceChanged = () => {
    console.log('place changed');
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const formattedAddress = place.formatted_address;
      form.setFieldsValue({
        place: formattedAddress,
        latitude: place?.geometry?.location?.lat(),
        longitude: place?.geometry?.location?.lng(),
      });

      if (place.geometry?.location?.lat() && place.geometry?.location?.lng()) {
        setLatLng({
          lat: place.geometry?.location?.lat()?.toString(),
          lng: place.geometry?.location?.lng()?.toString(),
        });
      }
    }
  };

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onFinish = async (values: ContactFormValues) => {
    setLoading(true);
    if (props.editableData) {
      const updatedValues: ContactFormValues = {
        ...values,
        id: props.editableData.id,
        invited: props.editableData.invited,
        created_at: props.editableData.created_at,
        updated_at: props.editableData.updated_at,
        deleted_at: props.editableData.deleted_at,
        agency_id: props.editableData.agency_id,
        move_date: (values.move_date as Moment)?.format('YYYY-MM-DD') as string,
      };
      await updateContact(updatedValues);
      notificationController.success({ message: 'Contact updated successfully', placement: 'bottomRight' });
    } else {
      const updatedValues: ContactFormValues = {
        ...values,
        move_date: (values.move_date as Moment)?.format('YYYY-MM-DD') as string,
      };
      await addContact(updatedValues);
      notificationController.success({ message: 'Contact added successfully', placement: 'bottomRight' });
    }
    setLoading(false);
    props.onSave();
  };

  return (
    <BaseModal
      destroyOnClose
      size="large"
      centered
      open={props.open}
      onCancel={props.onClose}
      onOk={handleSave}
      closable={false}
      title={props.editableData ? 'Edit Contact' : 'Add Contact'}
      bodyStyle={{ paddingTop: 4 }}
      footer={[
        <Button size="small" key="back" onClick={props.onClose} style={{ fontSize: 12 }}>
          Close
        </Button>,
        <Button
          loading={loading}
          size="small"
          key="submit"
          type="primary"
          onClick={handleSave}
          style={{ fontSize: 12 }}
        >
          {props.editableData ? 'Update Contact' : 'Add Contact'}
        </Button>,
      ]}
    >
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} md={24} xl={12}>
          {isLoaded ? (
            <GoogleMap
              center={{
                lat: parseFloat(latLng.lat),
                lng: parseFloat(latLng.lng),
              }}
              zoom={14}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                fullscreenControl: false,
                streetViewControl: false,
              }}
              mapContainerStyle={{ height: '100%', width: '100%' }}
              onClick={(e) => {
                console.log(e);
              }}
            >
              <MarkerF
                position={{
                  lat: parseFloat(latLng.lat),
                  lng: parseFloat(latLng.lng),
                }}
              />
            </GoogleMap>
          ) : (
            <></>
          )}
        </BaseCol>
        <BaseCol xs={24} md={24} xl={12}>
          <BaseButtonsForm
            initialValues={formValues}
            form={form}
            name="info"
            loading={loading}
            isFieldsChanged={false}
            onFinish={onFinish}
          >
            <BaseRow gutter={{ xs: 10, md: 8, xl: 30 }}>
              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                      pattern: /^[A-Za-z\s]+$/,
                      message: 'Please enter a valid First Name (letters and spaces only).',
                    },
                  ]}
                  name="first_name"
                  label={'First Name'}
                  $padding="0 0 0px"
                >
                  <BaseInput placeholder="Enter first name" />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                      pattern: /^[A-Za-z\s]+$/,
                      message: 'Please enter a valid Last Name (letters and spaces only).',
                    },
                  ]}
                  name="last_name"
                  label={'Last Name'}
                  $padding="0 0 0px"
                >
                  <BaseInput placeholder="Enter last name" />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={24}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?!(?:.*\.com){2,})$/,
                      message: 'Please enter a valid email address.',
                    },
                  ]}
                  name="email"
                  label={'Email'}
                  $padding="0 0 0px"
                >
                  <BaseInput placeholder="Enter valid email" />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name="phone"
                  label={'Phone Number'}
                  $padding="0 0 0px"
                >
                  <BaseInput placeholder="Enter phone number" />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item name="move_date" label="Move in date" $padding="0 0 0px">
                  <DatePicker format={DateFormat.YYYY_MM_DD} placeholder="Move in date" style={{ width: '100%' }} />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name="beds"
                  label={'Bedrooms'}
                  $padding="0 0 0px"
                >
                  <InputNumber block placeholder="No of bedroom" min={1} max={5} />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name="budget"
                  label={'Max Budget'}
                  $padding="0 0 0px"
                >
                  <InputNumber block placeholder="Enter budget" min={0} max={10000000} />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={24}>
                {isLoaded && (
                  <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                    <BaseButtonsForm.Item
                      rules={[
                        {
                          required: true,
                          pattern: /^[A-Za-z\s]+$/,
                          message: 'Please enter a valid Place Name (letters and spaces only).',
                        },
                      ]}
                      name="place"
                      label={'Area'}
                      $padding="0 0 0px"
                    >
                      <BaseInput />
                    </BaseButtonsForm.Item>
                  </Autocomplete>
                )}
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item hidden name="latitude" label={'Area'} $padding="0 0 0px">
                  <BaseInput />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item hidden name="longitude" label={'Area'} $padding="0 0 0px">
                  <BaseInput />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name="radius"
                  label={'Radius in mile'}
                  $padding="0 0 0px"
                >
                  <InputNumber block placeholder="Enter Radius" min={0} max={100} />
                </BaseButtonsForm.Item>
              </BaseCol>

              <BaseCol xs={24} md={12}>
                <BaseButtonsForm.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name="trans_type_id"
                  label={'Type'}
                  $padding="0 0 0px"
                >
                  <BaseSelect width={'100%'} placeholder="Select Type">
                    <Option value={1}>Lettings</Option>
                    <Option value={2}>Sales</Option>
                  </BaseSelect>
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseButtonsForm>
        </BaseCol>
      </BaseRow>
    </BaseModal>
  );
};

export default AddEditContactModal;
