import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import AgentDashboardPage from '@app/pages/DashboardPages/agencyDashboardPages/AgentDashboardPage';
import RenterDashboardPage from '@app/pages/DashboardPages/renterDashboardPages/RenterDashboardPage';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { UserRole } from '@app/constants/enums/userRoles';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/DashboardPages/profilePages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/DashboardPages/profilePages/SecuritySettingsPage'));
const ProfileSummaryPage = React.lazy(() => import('@app/pages/DashboardPages/profilePages/ProfileSummaryPage'));
const RentingHistoryPage = React.lazy(() => import('@app/pages/DashboardPages/profilePages/RentingHistoryPage'));
const UploadedFilePage = React.lazy(() => import('@app/pages/DashboardPages/profilePages/UploadedFilesPage'));
const FinancePage = React.lazy(() => import('@app/pages/DashboardPages/profilePages/FinancePage'));
const ReferencePage = React.lazy(() => import('@app/pages/DashboardPages/profilePages/ReferencePage'));
const AgencyInfoPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/AgencyInfoPages/AgencyInfoPage'),
);
const InvitePage = React.lazy(() => import('@app/pages/DashboardPages/agencyDashboardPages/InvitePages/InvitePage'));
const LandlordsPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/LandlordPages/LandLordsPage'),
);
const LandlordDetailsPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/LandlordPages/LandLordDetailsPage'),
);
const ContactListPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/ContactsPage/ContactListPage'),
);
const ContactsProfilePage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/ContactsPage/ContactProfilePage'),
);
const LettingsPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/LettingsPages/LettingsPage'),
);
const LettingsPropertiesPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/LettingsPages/LettingsPropertiesPage'),
);

const PricingPlansPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PricingPlansPages/PricingPlanPage'),
);
const AppraisalsPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PromotionsPages/AppraisalPage'),
);
const EmailMarketingPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PromotionsPages/EmailMarketingPage'),
);
const LiveViewingsPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PromotionsPages/LiveViewingPage'),
);
const SaleOrLetAgreedPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PromotionsPages/SaleOrLetAgreedPage'),
);
const SummaryPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PromotionsPages/SummaryPage'),
);

const AddPropertiesPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PropertiesPages/AddPropertiesPage'),
);
const PropertyListPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PropertiesPages/PropertyListPage'),
);

const PropertyDetailsPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PropertiesPages/PropertyDetailsPage'),
);

const PropertyViewPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/PropertiesPages/PropertyViewPage'),
);

const PotentialsSellersPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/SalesPages/PotentialsSellersPage'),
);

const OnlineViewingPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/Viewings/OnlineViewingMapPage'),
);

const SalesPage = React.lazy(() => import('@app/pages/DashboardPages/agencyDashboardPages/SalesPages/SalesPage'));
const SalesPropertiesPage = React.lazy(
  () => import('@app/pages/DashboardPages/agencyDashboardPages/SalesPages/SalesPropertiesPage'),
);

const AgencySharingHistoryPage = React.lazy(
  () => import('@app/pages/DashboardPages/renterDashboardPages/AgencySharingHistory/AgencySharingHistoryPage'),
);

const MessagePage = React.lazy(() => import('@app/pages/DashboardPages/renterDashboardPages/MessagePage'));

const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

const RenterDashboard = withLoading(RenterDashboardPage);
const AgentDashboard = withLoading(AgentDashboardPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const ProfileSummary = withLoading(ProfileSummaryPage);
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const RentingHistory = withLoading(RentingHistoryPage);
const UploadedFiles = withLoading(UploadedFilePage);
const Finance = withLoading(FinancePage);
const Reference = withLoading(ReferencePage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

//Agency Info
const AgencyInfo = withLoading(AgencyInfoPage);
//Invites
const Invite = withLoading(InvitePage);
//Landlords
const Landlords = withLoading(LandlordsPage);
const LandlordDetails = withLoading(LandlordDetailsPage);
//Lettings
const Lettings = withLoading(LettingsPage);
const LettingsProperties = withLoading(LettingsPropertiesPage);

//Contacts
const ContactsProfile = withLoading(ContactsProfilePage);
const ContactList = withLoading(ContactListPage);

//Sales
const Sales = withLoading(SalesPage);
const SalesProperties = withLoading(SalesPropertiesPage);
const PotentialsSellers = withLoading(PotentialsSellersPage);

//Pricing Plan
const PricingPlans = withLoading(PricingPlansPage);

//Promotions
const Appraisals = withLoading(AppraisalsPage);
const EmailMarketing = withLoading(EmailMarketingPage);
const LiveViewings = withLoading(LiveViewingsPage);
const SaleOrLetAgreed = withLoading(SaleOrLetAgreedPage);
const PromotionSummary = withLoading(SummaryPage);

//Properties
const PropertyList = withLoading(PropertyListPage);
const AddProperties = withLoading(AddPropertiesPage);
const PropertyDetails = withLoading(PropertyDetailsPage);
const PropertyView = withLoading(PropertyViewPage);

const AgencySharingHistory = withLoading(AgencySharingHistoryPage);
const MessagesLayout = withLoading(MessagePage);
const OnlineViewing = withLoading(OnlineViewingPage);

export const AppRouter: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const renderDashboard = () => {
    if (user?.role === UserRole.AGENCY_ADMIN) {
      return <AgentDashboard />;
    } else if (user?.role === UserRole.RENTER) {
      return <RenterDashboard />;
    } else {
      return <Navigate to="/auth/login" replace />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={renderDashboard()} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="summary" element={<ProfileSummary />} />
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="finances" element={<Finance />} />
            <Route path="renting-history" element={<RentingHistory />} />
            <Route path="uploaded-files" element={<UploadedFiles />} />
            <Route path="references" element={<Reference />} />
          </Route>
          <Route path="properties/new" element={<AddProperties />} />
          <Route path="properties/view" element={<PropertyView />} />
          <Route path="properties/bulk" element={<PropertyList />} />
          <Route path="properties/details" element={<PropertyDetails />} />

          <Route path="lettings" element={<Lettings />} />
          <Route path="lettings/properties" element={<LettingsProperties />} />

          <Route path="contacts/details" element={<ContactsProfile />} />
          <Route path="contacts/letting" element={<ContactList />} />
          <Route path="contacts/sale" element={<ContactList />} />

          <Route path="sales" element={<Sales />} />
          <Route path="sales/properties" element={<SalesProperties />} />
          <Route path="sales/potential-sellers" element={<PotentialsSellers />} />

          <Route path="landlords" element={<Landlords />} />
          <Route path="landlords/details" element={<LandlordDetails />} />
          <Route path="vendors/details" element={<LandlordDetails />} />
          <Route path="vendors" element={<Landlords />} />
          <Route path="agency-info" element={<AgencyInfo />} />

          <Route path="promotion" element={<PromotionSummary />} />
          <Route path="promotion/appraisal" element={<Appraisals />} />
          <Route path="promotion/agreed" element={<SaleOrLetAgreed />} />
          <Route path="promotion/live-viewings" element={<LiveViewings />} />
          <Route path="promotion/email-marketing" element={<EmailMarketing />} />
          <Route path="pricing-plans" element={<PricingPlans />} />
          <Route path="invite" element={<Invite />} />

          <Route path="online-viewing-map" element={<OnlineViewing />} />

          <Route path="privacy" element={<AgencySharingHistory />} />
          <Route path="messages" element={<MessagesLayout />} />
        </Route>

        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
