import React, { useState } from 'react';
import { NotificationsDropdown } from '../notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../profileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { pullProperties } from '@app/api/properties/properties.api';
import { UserRole } from '@app/constants/enums/userRoles';
import { HeaderActionWrapper } from '../Header.styles';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { MessageOutlined, SyncOutlined, UserAddOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Popconfirm } from 'antd';
import { faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const [isLoading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.user.user);

  const isAgencyAdmin = () => {
    return user?.role === UserRole.AGENCY_ADMIN;
  };

  const pullPropertiesFromDaft = async () => {
    console.log('pullPropertiesFromDaft');
    setLoading(true);
    const res = await pullProperties();
    setLoading(false);
    notificationController.success({ message: res.message });
  };

  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>
        <ProfileDropdown />
      </BaseCol>

      <BaseCol>
        <BaseRow align="middle">
          {isAgencyAdmin() && (
            <>
              <BaseCol>
                <HeaderActionWrapper>
                  <BaseTooltip placement="bottom" title={'Pull properties from daft'}>
                    <Popconfirm title="Pull the properties from Daft.ie?" onConfirm={pullPropertiesFromDaft}>
                      <BaseButton type={'text'} icon={<SyncOutlined spin={isLoading} />} />
                    </Popconfirm>
                  </BaseTooltip>
                </HeaderActionWrapper>
              </BaseCol>

              {/* <BaseCol>
                <HeaderActionWrapper>
                  <BaseTooltip placement="bottom" title={'Add Contact'}>
                    <BaseButton type={'text'} icon={<UserAddOutlined />} />
                  </BaseTooltip>
                </HeaderActionWrapper>
              </BaseCol>

              <BaseCol>
                <HeaderActionWrapper>
                  <BaseTooltip placement="bottom" title={'Broadcast a viewing'}>
                    <BaseButton type={'text'}>
                      <FontAwesomeIcon icon={faTowerBroadcast} />
                    </BaseButton>
                  </BaseTooltip>
                </HeaderActionWrapper>
              </BaseCol>

              <BaseCol>
                <HeaderActionWrapper>
                  <BaseTooltip placement="bottom" title={'Messages'}>
                    <BaseButton type={'text'} icon={<MessageOutlined />} />
                  </BaseTooltip>
                </HeaderActionWrapper>
              </BaseCol> */}
            </>
          )}
          <BaseCol>
            <NotificationsDropdown />
          </BaseCol>

          <BaseCol>
            <SettingsDropdown />
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
      </S.BurgerCol>
    </BaseRow>
  );
};
