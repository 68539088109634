import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import AgentDashboard from '@app/components/agent-dashboard/AgentDashboard';

const AgentDashboardPage: React.FC = () => {
  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <AgentDashboard />
    </>
  );
};

export default AgentDashboardPage;
