import { fetchSalesBuyers, fetchSalesViewings } from '@app/api/sales/sales.api';
import { Buyer } from '@app/domain/Buyer';
import { Viewing } from '@app/domain/Viewing';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  buyers: Buyer[];
  viewings: Viewing[];
}

const initialState: InitialState = {
  buyers: [],
  viewings: [],
};

export const getBuyers = createAsyncThunk('sales/getBuyers', async () => {
  try {
    const res = await fetchSalesBuyers();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getSalesViewing = createAsyncThunk('sales/getSalesViewing', async () => {
  try {
    const res = await fetchSalesViewings();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBuyers.fulfilled, (state, action) => {
      state.buyers = action.payload;
    });
    builder.addCase(getSalesViewing.fulfilled, (state, action) => {
      state.viewings = action.payload;
    });
  },
});

export default salesSlice.reducer;
