import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { UserRole } from '@app/constants/enums/userRoles';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);
  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={sidebarNavigation
        .map((nav) => {
          const isSubMenu = nav.children?.length;

          // Check if the role is allowed for the navigation item
          const isAllowedRole = nav.allowedRoles.includes(user?.role as UserRole);

          // Return the navigation item only if the role is allowed
          if (!isAllowedRole) {
            return null;
          }

          return {
            key: nav.key,
            title: t(nav.title),
            label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
            icon: nav.icon,
            children:
              isSubMenu &&
              nav.children &&
              nav.children.map((childNav) => {
                const isSubSubMenu = childNav?.children?.length;
                return {
                  key: childNav.key,
                  label: isSubSubMenu ? t(childNav.title) : <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                  title: t(childNav.title),
                  icon: childNav.icon,
                  children: childNav?.children?.map((subChildNav) => {
                    return {
                      key: subChildNav.key,
                      label: <Link to={subChildNav.url || ''}>{t(subChildNav.title)}</Link>,
                      title: t(subChildNav.title),
                      icon: subChildNav.icon,
                    };
                  }),
                };
              }),
          };
        })
        .filter(Boolean)}
    />
  );
};

export default SiderMenu;
