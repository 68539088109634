import {
  fetchLettingsApplications,
  fetchLettingsRenter,
  fetchLettingsRequest,
  fetchLettingsViewing,
} from '@app/api/lettings/lettings.api';
import { Plan } from '@app/constants/enums/plan';
import { Viewing } from '@app/domain/Viewing';
import { LettingsRenter } from '@app/domain/lettings/LettingsRenter';
import { LettingsRequest } from '@app/domain/lettings/LettingsRequest';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface LettingSlice {
  requests: LettingsRequest[];
  renters: LettingsRenter[];
  viewing: Viewing[];
  applications: LettingsRenter[];
}

const initialState: LettingSlice = {
  requests: [],
  renters: [],
  viewing: [],
  applications: [],
};

export const getLettingsRenter = createAsyncThunk('lettings/getLettingsRenter', async () => {
  try {
    const res = await fetchLettingsRenter();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getLettingsRequest = createAsyncThunk('lettings/getLettingsRequest', async () => {
  try {
    const res = await fetchLettingsRequest();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getLettingsViewing = createAsyncThunk('lettings/getLettingsViewing', async () => {
  try {
    const res = await fetchLettingsViewing();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const getLettingsApplication = createAsyncThunk('lettings/getLettingsApplication', async () => {
  try {
    const res = await fetchLettingsApplications();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

const lettingSlice = createSlice({
  name: 'lettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLettingsRenter.fulfilled, (state, action) => {
      state.renters = action.payload.filter(
        (renter) => renter.plan !== Plan.VERIFIED && renter.plan !== Plan.CONCIERGE,
      );

      const uniqueApplications = action.payload.filter((newApp) => {
        return (
          !state.applications.some((app) => app.id === newApp.id) &&
          (newApp.plan === Plan.VERIFIED || newApp.plan === Plan.CONCIERGE)
        );
      });
      state.applications = [...state.applications, ...uniqueApplications];
    });
    builder.addCase(getLettingsRequest.fulfilled, (state, action) => {
      state.requests = action.payload;
    });
    builder.addCase(getLettingsViewing.fulfilled, (state, action) => {
      state.viewing = action.payload;
    });
    builder.addCase(getLettingsApplication.fulfilled, (state, action) => {
      const newApplications: LettingsRenter[] = action.payload.map((application) => {
        return {
          id: application.id,
          pivot: null,
          let_search: [],
          corenters: [],
          first_name: application.profile.first_name,
          last_name: application.profile.last_name,
          email: application.profile.email,
          phone: application.profile.phone,
          picture: application.profile.picture,
          information: application.profile.information,
          employment_id: application.profile.employment_id,
          created_at: application.profile.created_at,
          updated_at: application.profile.updated_at,
          deleted_at: application.profile.deleted_at,
          plan: application.profile.plan,
          stripe_intent_id: application.profile.stripe_intent_id,
          invited_by: application.profile.invited_by,
          for_delete: application.profile.for_delete,
          role: application.profile.role,
          job: application.profile.job,
        };
      });
      const uniqueApplications = newApplications.filter((newApp) => {
        return !state.applications.some((app) => app.id === newApp.id);
      });
      state.applications = [...state.applications, ...uniqueApplications];
    });
  },
});

export default lettingSlice.reducer;
