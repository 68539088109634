import { Reference, Search, UserModel } from '@app/domain/UserModel';
import { httpApi } from '../http.api';
import { UploadFile } from 'antd';
import { ReferenceCard } from '@app/interfaces/interfaces';
import { CommonResponse } from '@app/domain/CommonResponse';
import { Profile } from '@app/domain/Profile';

export const updateProfile = (user: UserModel | null): Promise<UserModel> =>
  httpApi.put<UserModel>(`profile/${user?.id}`, { ...user }).then(({ data }) => data);

export const uploadProfilePicture = async (file: UploadFile): Promise<string> => {
  httpApi.interceptors.request.use((config) => {
    config.headers['Content-Type'] = `multipart/form-data;`;
    return config;
  });
  const { data } = await httpApi.post('profile/image', { file: file });
  return data;
};

export const searchesProfile = (): Promise<Search[]> =>
  httpApi.get<Search[]>('searches/profile').then(({ data }) => data);

export const uploadDocument = async (
  profileId: number,
  file: UploadFile,
  onUploadProgress: (percentCompleted: number) => void,
): Promise<string> => {
  httpApi.interceptors.request.use((config) => {
    config.headers['Content-Type'] = `multipart/form-data;`;
    config.onUploadProgress = (progressEvent) =>
      onUploadProgress(Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1)));
    return config;
  });
  const { data } = await httpApi.post('profile/document', { profile_id: profileId, file: file });
  return data;
};

export const deleteDocument = (docId: number): Promise<void> =>
  httpApi.get<void>(`profile/document/${docId}/delete`).then(({ data }) => data);

export const downloadDocument = async (docId: number, fileName: string): Promise<void> => {
  try {
    const response = await httpApi.get(`profile/document/${docId}`, { responseType: 'blob' });

    // Create a temporary link element to download the PDF
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = fileName;
    link.target = '_blank';

    // Trigger the download
    link.click();

    // Clean up the temporary link
    URL.revokeObjectURL(link.href);

    console.log('PDF file downloaded successfully.');
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw error;
  }
};

export const addReference = (references: Reference | ReferenceCard): Promise<Reference> =>
  httpApi.post<Reference>(`profile/references`, { ...references }).then(({ data }) => data);

export const updateReference = (references: Reference): Promise<Reference> =>
  httpApi.put<Reference>(`profile/references/${references.id}`, { ...references }).then(({ data }) => data);

export const deleteReference = (refId: number) =>
  httpApi.delete(`profile/references/${refId}`).then(({ data }) => data);

export const addCoRenter = (email: string): Promise<CommonResponse> =>
  httpApi.post<CommonResponse>(`profile/add-corenter`, { email: email }).then(({ data }) => data);

export const fetchProfiles = (): Promise<Profile[]> => httpApi.get<Profile[]>(`profiles`).then(({ data }) => data);
