import { Activity } from '@app/domain/Activity';
import { httpApi } from './http.api';
import { RequestAcceptResponse } from '@app/domain/RequestAcceptResponse';

export const getUserActivities = (): Promise<Activity[]> =>
  httpApi.get<Activity[]>('activities').then(({ data }) => data);

export const markAsReadActivity = (activityId: number): Promise<void> =>
  httpApi.delete<void>(`activities/${activityId}`).then(({ data }) => data);

export const acceptPropertyRequest = (agencyId: number): Promise<RequestAcceptResponse> =>
  httpApi.post<RequestAcceptResponse>(`agencies/${agencyId}/share`).then(({ data }) => data);
