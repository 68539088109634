import React from 'react';
import {
  CompassOutlined,
  DashboardOutlined,
  HomeOutlined,
  LayoutOutlined,
  MessageOutlined,
  LockOutlined,
  DingtalkOutlined,
  AppstoreAddOutlined,
  OrderedListOutlined,
  CloudServerOutlined,
  CheckCircleOutlined,
  MailOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { UserRole } from '@app/constants/enums/userRoles';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  allowedRoles: UserRole[];
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.dashboard',
    key: 'dashboard',
    url: '/',
    icon: <DashboardOutlined />,
    allowedRoles: [UserRole.AGENCY_ADMIN, UserRole.RENTER],
  },
  {
    title: 'Privacy',
    key: 'privacy',
    url: '/privacy',
    icon: <LockOutlined />,
    allowedRoles: [UserRole.RENTER],
  },
  {
    title: 'Properties',
    key: 'properties',
    icon: <OrderedListOutlined />,
    allowedRoles: [UserRole.AGENCY_ADMIN],
    children: [
      {
        title: 'Add Property',
        key: 'properties-add',
        url: '/properties/new',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      {
        title: 'Property List',
        key: 'property-list',
        url: '/properties/bulk',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
    ],
  },
  {
    title: 'Lettings',
    key: 'lettings',
    icon: <CompassOutlined />,
    allowedRoles: [UserRole.AGENCY_ADMIN],
    children: [
      {
        title: 'Summary',
        key: 'lettings-info',
        url: '/lettings',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      // {
      //   title: 'Properties',
      //   key: 'properties-lettings',
      //   url: '/lettings/properties',
      //   allowedRoles: [UserRole.AGENCY_ADMIN],
      // },
      {
        title: 'Contacts',
        key: 'contacts-lettings',
        url: '/contacts/letting',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
    ],
  },
  {
    title: 'Sales',
    key: 'sales-summary',
    icon: <LayoutOutlined />,
    allowedRoles: [UserRole.AGENCY_ADMIN],
    children: [
      {
        title: 'Summary',
        key: 'sales',
        url: '/sales',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      // {
      //   title: 'Properties',
      //   key: 'properties-sales',
      //   url: '/sales/properties',
      //   allowedRoles: [UserRole.AGENCY_ADMIN],
      // },
      {
        title: 'Contacts',
        key: 'contacts-sales',
        url: '/contacts/sale',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
    ],
  },
  {
    title: 'CRM',
    key: 'crm',
    icon: <CloudServerOutlined />,
    allowedRoles: [UserRole.AGENCY_ADMIN],
    children: [
      {
        title: 'Landlords',
        key: 'landlords',
        url: '/landlords',
        icon: <HomeOutlined />,
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      {
        title: 'Vendors',
        key: 'vendors',
        url: '/vendors',
        icon: <AppstoreAddOutlined />,
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      // {
      //   title: 'Potential Sellers',
      //   key: 'potential-sellers',
      //   url: '/sales/potential-sellers',
      //   icon: <TeamOutlined />,
      //   allowedRoles: [UserRole.AGENCY_ADMIN],
      // },
    ],
  },
  // {
  //   title: 'common.messages',
  //   key: 'messages',
  //   url: '/messages',
  //   icon: <MessageOutlined />,
  //   allowedRoles: [UserRole.RENTER],
  // },
  {
    title: 'Promotion',
    key: 'promotion',
    icon: <DingtalkOutlined />,
    allowedRoles: [UserRole.AGENCY_ADMIN],
    children: [
      {
        title: 'Summary',
        key: 'summary',
        url: '/promotion',
        icon: <DashboardOutlined />,
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      {
        title: 'Appraisal',
        key: 'appraisal',
        icon: <HomeOutlined />,
        url: '/promotion/appraisal',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      {
        title: 'Sale/Let Agreed',
        key: 'agreed',
        icon: <CheckCircleOutlined />,
        url: '/promotion/agreed',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      {
        title: 'Live Viewings',
        key: 'live-viewings',
        icon: <EyeTwoTone />,
        url: '/promotion/live-viewings',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      {
        title: 'Email Marketing',
        key: 'email-marketing',
        icon: <MailOutlined />,
        url: '/promotion/email-marketing',
        allowedRoles: [UserRole.AGENCY_ADMIN],
      },
      // {
      //   title: 'Invite',
      //   key: 'invite',
      //   url: '/invite',
      //   allowedRoles: [UserRole.AGENCY_ADMIN],
      // },
    ],
  },
];
