import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileNavItem, profileNavData } from '@app/constants/profileNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './ProfileNav.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const ProfileNav: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const location = useLocation();

  const handleProfileNavPress = (item: ProfileNavItem) => {
    item.roles.find((role) => role === user?.role) ? navigate(item.href) : navigate('personal-info');
  };

  return (
    <S.Wrapper>
      {profileNavData.map(
        (item) =>
          item.roles.find((role) => role === user?.role) && (
            <S.Btn
              key={item.id}
              icon={item.icon}
              type="text"
              color={item.color}
              onClick={() => handleProfileNavPress(item)}
              $isActive={`/profile/${item.href}` === location.pathname}
            >
              {t(item.name)}
            </S.Btn>
          ),
      )}
    </S.Wrapper>
  );
};
