import { Agency } from '@app/domain/Agency';
import { readData } from '@app/services/localStorage.service';
import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StorageKey } from '../../services/storageKey';
import { fetchAgencyInfo } from '@app/api/agency.api';

export interface InitialState {
  agencyInfo: Agency | null;
  sharedAgencies: Agency[];
}

const initialState: InitialState = {
  agencyInfo: readData(StorageKey.AGENCY_INFO) as Agency,
  sharedAgencies: [],
};

export const getAgencyInfo = createAsyncThunk('agency/getAgencyInfo', async () => {
  try {
    const res = await fetchAgencyInfo();
    return res;
  } catch (error) {
    // Handle the error (e.g., dispatch an error action or show an error message)
    throw error;
  }
});

export const setSharedAgencyList = createAction<PrepareAction<Agency[]>>('agency/setSharedAgencyList', (agencies) => {
  return {
    payload: agencies,
  };
});

export const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAgencyInfo.fulfilled, (state, action) => {
      state.agencyInfo = action.payload;
    });

    builder.addCase(setSharedAgencyList, (state, action) => {
      state.sharedAgencies = action.payload;
    });
  },
});

export default agencySlice.reducer;
